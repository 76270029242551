<template>
  <div>
    <div v-for="(i, idx) in data" :key="idx">
      <!-- {{ i.content }} -->
      <!-- info client -->
      <div class="d-flex justify-start">
        <p class="text--title mt-1 mr-3 text-cus">
          {{ i.content.name }}
        </p>

        <v-btn
          class="btn-crm-primary tex-btn-cus"
          :to="
            '/customer-details/' + i.clientId + '?typeClient=' + i.content.type
          "
          target="_blank"
        >
          {{ $t('myPage.myPage.OpenMemberInformation') }}
        </v-btn>
      </div>
      <!-- end info client -->

      <!-- content -->
      <div>
        <Comment page="myPage" :comment="i.content" @reset="reset" @comment="(payload) => replyComment(i.clientId, payload)"/>
      </div>
      <!-- End content -->
    </div>

    <!-- Pagination bottom -->
    <CommnetPaginate 
      :total="getTotalComment" 
      :itemPerPage="getSelectPaginateMyPage"
      :items="[5, 10, 20, 30, 40, 50, 100]"
      @reset="reset"
    />
    <!-- End pagination bottom -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { exportTimeJapan } from '@/constants/functions.js';
import Comment from '@/components/Comment/Comment'
import CommnetPaginate from '@/components/Comment/CommentPaginate.vue'

export default {
  name: 'commentList',
  components: {Comment, CommnetPaginate},
  data() {
    return {
      // valueCmtTemp: null,
      // actionCurrent: null,
      // isOpen_div: null,
      // quote: false,
      // commentParent: '',
      // dataAfter: [],
      // data_export: [],
      // dataCmt: this.data,
      // showRepCmt: false,

      // startSlice: 0,
      // endSlice: 5,
    };
  },
  
  props: {
    data: Array,
  },

  computed: {
    ...mapGetters([
      'commentMyPage',
      'commentClientMyPage',
      'commetListMyPage',
      'getSelectPaginateMyPage',
      'getTotalComment',
      'getSkipPaginateMyPage',
    ]),

    // getCommentMyPage: {
    //   get() {
    //     return this.commentMyPage;
    //   },
    //   set(value) {
    //     this.setCommentMyPage(value);
    //   },
    // },
  },

  // created() {
  //   if (this.skip === 0) {
  //     this.startRow = 1;
  //     this.endRow = this.selected;
  //   }
  // },
  methods: {
    ...mapActions(['createClientCommentMyPage']),
    ...mapMutations([
      'setCommentMyPage',
      'setAlertSuccess',
      'setSelectPaginateMyPage',
      'setSkipPaginateMyPage',
    ]),

    reset(res){
      this.setSkipPaginateMyPage(res.skip);
      this.setSelectPaginateMyPage(res.take);
      this.$store.dispatch('getCommetListMyPage');
    },

    exportTimeJapan,

    async replyComment(idClient, {comment}) {
      this.setCommentMyPage(comment);
      this.createClientCommentMyPage(idClient);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-rep {
  border: 2px solid #3faf7c;
  width: 100px;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.btn-quote {
  font-size: 15px;
  // color: #8a8d8c
  color: #3faf7c;
}
::v-deep {
  // .v-input .v-textarea{
  //   display: flex !important;
  //   // background-color: rgb(245, 251, 248);
  //   // border-color: rgb(245, 251, 248);
  //   flex-direction: column !important;
  // }
  .v-input__control {
    .v-input__slot {
      display: flex !important;
      flex-direction: column !important;
    }
  }

  .v-data-footer {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    .v-select__slot {
      border-bottom: 0.5px solid #888888;
      .v-select__selections {
        .v-select__selection--comma {
          color: #000 !important;
          font-weight: 500 !important;
          font-size: 12px !important;
        }
      }
      :hover > & {
        border-bottom: 0.5px solid black;
      }
    }
    .v-icon__svg {
      color: #333333 !important;
    }
  }
  .v-select-custom {
    width: 50px;
  }
  .divNone {
    display: none !important;
    margin-left: 6%;
  }
  .divBlock {
    display: block !important;
    margin-left: 6%;
  }
  .text-cus {
    color: #0c6786 !important;
  }
  .tex-btn-cus {
    color: #1873d9 !important;
    border: 0.5px solid #1873d9;
    border-radius: 4px;
    opacity: 1;
  }
}
</style>
