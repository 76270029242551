<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ActivityScheduleEditing from '@/views/customer/response/detail/Activities/ActivityScheduleEditing.vue';
import moment from 'moment';

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    ActivityScheduleEditing,
  },
  data: function () {
    return {
      menuDateCalendar: false,
      dateCalendar: moment().format('YYYY-MM-DD'),
      currentTable1RowId: null,
      visible:-1,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        initialView: 'timeGridFourDay',
        initialDate: new Date(),
        slotDuration: '01:00:00',
        slotMinTime: '08:00:00',
        allDaySlot: false,
        views: {
          timeGridFourDay: {
            type: 'timeGrid',
            duration: { days: 14 },
          },
        },
      },
      currentEvents: [],
    };
  },
  computed: {
    ...mapGetters([
      'calendarListMyPage',
      'dateFromCalendarMyPage',
      'dateToCalendarMyPage',
    ]),
    getDateToCalendarMyPage: {
      get() {
        return this.dateToCalendarMyPage;
      },
      set(value) {
        this.setDateToCalendarMyPage(value);
        this.getClientActionHistoryListMyPage();
      },
    },
    getDateFromCalendarMyPage: {
      get() {
        return this.dateFromCalendarMyPage;
      },
      set(value) {
        this.setDateFromCalendarMyPage(value);
        this.getClientActionHistoryListMyPage();
      },
    },
  },

  props: {
    startDate: String,
  },

  methods: {
    ...mapActions([
      'getClientActionHistoryListMyPage',
      'getCalendarListMyPage',
      'actionGetClientActionHistory',
    ]),

    // ...mapActions([
    //   'getClientActionHistoryListMyPage',
    //   'getCalendarListMyPage',
    //   'getCommetListMyPage',
    //   'actionGetClientActionHistory',
    //   'actionEnumPurchaseRateList',
    //   'actionEnumNegotiationExitReasonList',
    //   'actionEnumParticipantTypeList',
    //   'actionEnumClientActionMethodList',
    //   'actionEnumClientActionTypeList',
    // ]),

    ...mapMutations([
      'setDateToCalendarMyPage',
      'setDateFromCalendarMyPage',
      // 'setDateToMyPage',
      'setDateFromSelected',
      'setHoursSelected',
      'setMinutesSelected',
      // 'setActionAtSelected',
      'setParticipantsSelected',
      'setPrioritySelected',
      'setActionMethodIdSelected',
      // 'setPurchaseRateIdSelected',
      // 'setCurrentPurchaseRate',
      'setActionSelected',
      // 'setCurrentFurtherPurchaseRate',
      // 'setCurrentRenewPurchaseRate',
      // 'setFurtherPurchaseRateIdSelected',
      // 'setRenewPurchaseRateIdSelected',
      // 'setIsComplaintSelected',
      // 'setNegotiationExitReasonSelected',
      // 'setClientActionTypeIdSelected',
      // 'setRemarksSelected',
      // 'getMonCurrentWeek',
    ]),
    // format date header col
    formatDate(date, month, day) {
      var weekday = date.marker.toString().substr(0, 3);
      switch (weekday) {
        case 'Sun':
          weekday = '(日)';
          break;
        case 'Mon':
          weekday = '(月)';
          break;
        case 'Tue':
          weekday = '(火)';
          break;
        case 'Wed':
          weekday = '(水)';
          break;
        case 'Thu':
          weekday = '(木)';
          break;
        case 'Fri':
          weekday = '(金)';
          break;
        case 'Sat':
          weekday = '(土)';
          break;
      }
      return month + 1 + '/' + day + ' ' + weekday;
    },

    // Set color text header calendar
    setColorTextWeekday(day) {
      var date = new Date(day.marker);
      date.setDate(date.getDate() + 1);
      date.toISOString().substr(0, 10);
      var weekday = day.marker.toString().substr(0, 3);
      const dateTime = moment(date, 'YYYY-MM-DD');
      if (!dateTime.isBefore(moment())) {
        if (weekday === 'Sun') {
          return 'color: #ff0000';
        } else if (weekday === 'Sat') {
          return 'color: #009ce6';
        } else {
          return 'color: #000000';
        }
      } else {
        return 'color: #AAAAAA';
      }
    },

    // check time is pass
    checkTimeIsPass(date) {
      const dateTime = moment(
        date.toISOString().substr(0, 10) + date.toString().substr(15, 7),
        'YYYY-MM-DDHH:mm',
      );
      return dateTime.isBefore(moment());
    },

    // config calendar
    configCalendar(calendarListMyPage) {
      return {
        ...this.calendarOptions,
        events: calendarListMyPage,
      };
    },

    // select day date-picker calendar
    changeWeek() {
      // change date in date-picker
      this.getDateToCalendarMyPage = this.dateCalendar;

      // change dateFrom ang dateTo in store and load data
      this.getDateFromCalendarMyPage = this.dateCalendar;
      const toDate = new Date(new Date().getTime()+(14*24*60*60*1000)).toISOString().substr(0, 10)
      this.getDateToCalendarMyPage = toDate;
      this.getCalendarListMyPage();

      // change date in header calendar
      this.changeDate(this.dateCalendar);
    },

    // change date in header calendar
    changeDate(dateFrom) {
      var calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.gotoDate(dateFrom);
    },

    // next and back date-picker calendar
    actionCalendar(action) {
      let dateFrom = '';
      if (action === 0) {
        dateFrom = new Date(new Date(this.dateCalendar).getTime()-(14*24*60*60*1000)).toISOString().substr(0, 10)
      } else {
        dateFrom = new Date(new Date(this.dateCalendar).getTime()+(14*24*60*60*1000)).toISOString().substr(0, 10)
      }
      this.getDateFromCalendarMyPage = dateFrom;
      const dateTo = new Date(new Date(dateFrom).getTime()+(14*24*60*60*1000)).toISOString().substr(0, 10)
      this.getDateToCalendarMyPage = dateTo;
      this.dateCalendar = dateFrom;
      this.getCalendarListMyPage();

      // change date in header calendar
      this.changeDate(dateFrom);
    },

    showPopupActivityScheduleEdit(id) {
      this.actionGetClientActionHistory(id).then(clientActionHistory => {
        if (clientActionHistory.actionPlannedAt) {
          this.setDateFromSelected(
            moment(clientActionHistory.actionPlannedAt).format('YYYY-MM-DD'),
          );
          this.setHoursSelected(
            moment(clientActionHistory.actionPlannedAt).format('HH'),
          );
          this.setMinutesSelected(
            moment(clientActionHistory.actionPlannedAt).format('mm'),
          );
        } else {
          this.setDateFromSelected(null);
          this.setHoursSelected(null);
          this.setMinutesSelected(null);
        }
        if (clientActionHistory.participants) {
          this.setParticipantsSelected(clientActionHistory.participants);
        } else {
          this.setParticipantsSelected(null);
        }
        if (clientActionHistory.priority) {
          this.setPrioritySelected(clientActionHistory.priority);
        } else {
          this.setPrioritySelected(null);
        }
        if (clientActionHistory.actionMethodId) {
          this.setActionMethodIdSelected(clientActionHistory.actionMethodId);
        } else {
          this.setActionMethodIdSelected(null);
        }
        if (clientActionHistory.action) {
          this.setActionSelected(clientActionHistory.action);
        } else {
          this.setActionSelected(null);
        }
        this.currentTable1RowId = id;
        this.visible = 2;
      });
    },

    reloadTable () {
      this.getClientActionHistoryListMyPage();
    }
  },
};
</script>

<template>
  <div class="demo-app">
    <div class="header-list-custom mx-5 pt-2">
      <!-------- LEFT ITEMS ---------->
      <div class="d-flex align-center pr-5">
        <h2 class="h2-cus">活動予定カレンダー</h2>
        <v-btn
          outlined
          small
          color="var(--text_gray)"
          class="mx-4 bg--white"
          @click="
            () => {
              const nowDate = new Date().toISOString().substr(0, 10);
              setDateToCalendarMyPage(nowDate);
              dateCalendar = nowDate;
              changeWeek();
            }
          "
          >今日</v-btn
        >
        <div class="d-flex align-center">
          <v-menu
            v-model="menuDateCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-btn
                  outlined
                  small
                  color="var(--text_gray)"
                  class="bg--white"
                  hide-details
                  v-on="on"
                  ><span class="text-10px" style="color: #000000">{{
                    dateCalendar
                  }}</span></v-btn
                >
              </div>
            </template>
            <v-date-picker
              v-model="dateCalendar"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @change="changeWeek"
              @input="menuDateCalendar = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center ml-3">
          <div class="d-flex align-center ml-3">
            <button @click="actionCalendar(0)">
              <i class="fas fa-chevron-left mr-3"></i>
            </button>
            <button @click="actionCalendar(1)">
              <i class="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="demo-app-main">
      <FullCalendar
        ref="fullCalendar"
        class="demo-app-calendar"
        :options="configCalendar(calendarListMyPage)"
      >
        <template v-slot:eventContent="arg">
          <div
            style="height: 100%"
            :class="[
              arg.event.extendedProps.priority === 10 && 'important',
              checkTimeIsPass(arg.event.end) ? 'div-blur' : '',
            ]"
            @click="showPopupActivityScheduleEdit(parseInt(arg.event.id))"
          >
            <!-- <span>{{ arg.event.title !== "null" ? arg.event.title : '' }}</span> -->
            <span v-if="arg.event.extendedProps.action"
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100px;
              "
              >{{ arg.event.extendedProps.action }}
              <br />
            </span>
            <span>{{ arg.event.extendedProps.name }}</span>
          </div>
        </template>
        <template v-slot:dayHeaderFormat="arg">
          <div :style="setColorTextWeekday(arg.date)">
            {{ formatDate(arg.date, arg.date.month, arg.date.day) }}
          </div>
        </template>
      </FullCalendar>
    </div>

    <!-- Dialog -->
    <activity-schedule-editing
        :visible="visible === 2"
        @close="visible = -1"
        :currentTable1RowId="currentTable1RowId"
        @reloadTable="reloadTable"
      ></activity-schedule-editing>
  </div>
</template>

<style lang="scss">
// header
th.fc-col-header-cell {
  div.fc-scrollgrid-sync-inner {
    background-color: #b7deec !important;
    // padding: 30px;
    font-weight: 500;
    a {
      font-size: 12px;
    }
    div.text-cell-custom {
      color: #000000 !important;
    }
  }
}
tbody div.fc-timegrid-slot-label-cushion {
  height: 80px !important;
  color: #000000 !important;
  font-size: 12px !important;
}

// div content
.fc-event-main {
  background-color: #006dab;
  padding: 0 !important;
  border-radius: 4px;
}
.fc-timegrid-event {
  border: 1px solid white;
  border-radius: 5px;
}
.important {
  background-color: #db3069;
  border: 1px solid #db3069;
  border-radius: 3px;
  &.div-blur {
    background-color: #d398ac !important;
    border: 1px solid #d398ac !important;
  }
}
.div-blur {
  background-color: #64bceb !important;
  border: 1px solid #64bceb !important;
  border-radius: 3px;
}
div.fc-header-toolbar {
  display: none !important;
}
td.fc-timegrid-slot-label {
  background-color: #f8f8f8;
}
th.fc-timegrid-axis {
  background-color: #f8f8f8;
}
.h2-cus {
  text-align: left;
  font: normal normal bold 20px/30px Noto Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
</style>
