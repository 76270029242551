var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-app"},[_c('div',{staticClass:"header-list-custom mx-5 pt-2"},[_c('div',{staticClass:"d-flex align-center pr-5"},[_c('h2',{staticClass:"h2-cus"},[_vm._v("活動予定カレンダー")]),_c('v-btn',{staticClass:"mx-4 bg--white",attrs:{"outlined":"","small":"","color":"var(--text_gray)"},on:{"click":function () {
            var nowDate = new Date().toISOString().substr(0, 10);
            _vm.setDateToCalendarMyPage(nowDate);
            _vm.dateCalendar = nowDate;
            _vm.changeWeek();
          }}},[_vm._v("今日")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',[_c('v-btn',_vm._g({staticClass:"bg--white",attrs:{"outlined":"","small":"","color":"var(--text_gray)","hide-details":""}},on),[_c('span',{staticClass:"text-10px",staticStyle:{"color":"#000000"}},[_vm._v(_vm._s(_vm.dateCalendar))])])],1)]}}]),model:{value:(_vm.menuDateCalendar),callback:function ($$v) {_vm.menuDateCalendar=$$v},expression:"menuDateCalendar"}},[_c('v-date-picker',{staticClass:"v-date-picker-custom",attrs:{"first-day-of-week":0,"locale":_vm.$i18n.locale,"scrollable":""},on:{"change":_vm.changeWeek,"input":function($event){_vm.menuDateCalendar = false}},model:{value:(_vm.dateCalendar),callback:function ($$v) {_vm.dateCalendar=$$v},expression:"dateCalendar"}})],1)],1),_c('div',{staticClass:"d-flex align-center ml-3"},[_c('div',{staticClass:"d-flex align-center ml-3"},[_c('button',{on:{"click":function($event){return _vm.actionCalendar(0)}}},[_c('i',{staticClass:"fas fa-chevron-left mr-3"})]),_c('button',{on:{"click":function($event){return _vm.actionCalendar(1)}}},[_c('i',{staticClass:"fas fa-chevron-right"})])])])],1)]),_c('div',{staticClass:"demo-app-main"},[_c('FullCalendar',{ref:"fullCalendar",staticClass:"demo-app-calendar",attrs:{"options":_vm.configCalendar(_vm.calendarListMyPage)},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('div',{class:[
            arg.event.extendedProps.priority === 10 && 'important',
            _vm.checkTimeIsPass(arg.event.end) ? 'div-blur' : '' ],staticStyle:{"height":"100%"},on:{"click":function($event){_vm.showPopupActivityScheduleEdit(parseInt(arg.event.id))}}},[(arg.event.extendedProps.action)?_c('span',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","max-width":"100px"}},[_vm._v(_vm._s(arg.event.extendedProps.action)+" "),_c('br')]):_vm._e(),_c('span',[_vm._v(_vm._s(arg.event.extendedProps.name))])])]}},{key:"dayHeaderFormat",fn:function(arg){return [_c('div',{style:(_vm.setColorTextWeekday(arg.date))},[_vm._v(" "+_vm._s(_vm.formatDate(arg.date, arg.date.month, arg.date.day))+" ")])]}}])})],1),_c('activity-schedule-editing',{attrs:{"visible":_vm.visible === 2,"currentTable1RowId":_vm.currentTable1RowId},on:{"close":function($event){_vm.visible = -1},"reloadTable":_vm.reloadTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }