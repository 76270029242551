<template>
  <mypage>
    <template v-slot:default>
      <div class="mx-10">
        <!-- Activity schedule -->
        <v-card>
          <div class="header-list-custom mx-5 pt-2">
            <!-------- LEFT ITEMS ---------->
            <div class="d-flex align-center pr-5">
              <h2 class="h2-cus">活動予定</h2>
              <v-btn
                outlined
                small
                color="var(--text_gray)"
                class="mx-4 bg--white"
                @click="() => {
                  const nowDate = new Date().toISOString().substr(0, 10);
                  getDateToMyPage = nowDate;
                  date = nowDate;
                }"
                >今日</v-btn
              >
              <div class="d-flex align-center">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <div>
                      <v-btn
                        outlined
                        small
                        color="var(--text_gray)"
                        class="bg--white"
                        hide-details
                        v-on="on"
                        ><span class="text-10px" style="color: #000000">{{
                          getDateToMyPage
                        }}</span></v-btn
                      >
                    </div>
                  </template>
                  <v-date-picker
                    v-model="date"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    @change="getDateToMyPage = date"
                    @input="menuDate = false"
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="d-flex align-center ml-3">
                <button @click="action(0)">
                  <i class="fas fa-chevron-left mr-3"></i>
                </button>
                <button @click="action(1)">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
          <Table
            :attr="{
              'no-data-text': $t('rules.noData'),
              dense: true,
            }"
            ref="table"
            :headers="headerActivitySchedule"
            :items="clientActionHistoryListMyPage"
            :funReset="getClientActionHistoryListMyPage"
            :hideFooter="false"
            :disableSort="true"
          >
            <template v-slot:[`item.actionPlannedAt`]="{ item }">
              {{
                new Date(item.actionPlannedAt).toString().substr(16, 5)
              }}
              <!-- {{ item.actionPlannedAt && formatDate(item.actionPlannedAt) }} -->
            </template>
            <template v-slot:[`item.participants`]="{ item }">
              <span v-for="(name, idx) in item.participants" :key="idx"
                >{{ name
                }}<span v-if="idx < item.participants.length - 1"
                  >,&nbsp;</span
                ></span
              >
            </template>
            <template v-slot:[`item.clientName`]="{ item }">
              <span>{{ item.client.name }}</span>
            </template>
            <template v-slot:[`item.actionMethod`]="{ item }">
              {{ item.actionMethod ? item.actionMethod.name : '' }}
            </template>
            <template v-slot:[`item.priority`]="{ item }">
              <div v-if="item.priority >= 0">
                <span v-if="item.priority === 0">低</span>
                <span v-if="item.priority === 5">中</span>
                <span v-if="item.priority === 10">高</span>
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              {{ item.action ? item.action : '' }}
            </template>
            <template v-slot:[`item.MoveActivityHistory`]="{ item }">
              <v-chip
                @click="showPopupActivityHistory(item, item.id, 'Register')"
                color="#32B679"
                label
                text-color="white"
                :disabled="!checkPerUser"
              >
                活動履歴に移動
              </v-chip>
            </template>
            <!-- @click="showPopupActivityScheduleEdit(item.id)" -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn :disabled="!checkPerUser" icon @click="showPopupActivityScheduleEdit(item.id)">
                <v-icon x-large class="btn-crm-primary"  style="border: unset; background: unset !important">
                  mdi-pencil-circle
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.CustomerDetails`]="{item}">
              <v-btn icon class="t-btn--prm" :to="`/customer-details/${item.client.id}?idClient=${item.client.type}`" target="_blank">
                <v-icon> mdi-human-handsup</v-icon>
              </v-btn>
            </template>
          </Table>
        </v-card>
        <!-- End Activity schedule -->

        <!-- Activity schedule calendar -->
        <v-card class="mt-5 mb-8">
          <Calendar class="mt-5" />
        </v-card>
        <!-- End Activity schedule calendar -->

        <!-- Comment -->
        <div class="mb-16">
          <v-card outlined>
            <div class="listCmt">
              <!-- cmt -->
              <comment-list :data="commetListMyPage"></comment-list>
              <!-- end cmt -->
            </div>
          </v-card>
        </div>
        <!-- End Comment -->
      </div>
      <!-- dialog -->
      <activity-history
        :visible="visible === 1"
        @close="visible = -1"
        :currentTable1RowId="currentTable1RowId"
        :buttonType="buttonType"
        @reloadTable="reloadTable"
      ></activity-history>
      <activity-schedule-editing
        :visible="visible === 2"
        @close="visible = -1"
        :currentTable1RowId="currentTable1RowId"
        @reloadTable="reloadTable"
      ></activity-schedule-editing>
    </template>
  </mypage>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Mypage from '../Mypage.vue';
import CommentList from './commentList.vue';
import ActivityHistory from '@/views/customer/response/detail/Activities/ActivityHistory.vue';
import ActivityScheduleEditing from '@/views/customer/response/detail/Activities/ActivityScheduleEditing.vue';
import moment from 'moment';
import { exportTimeJapan } from '@/constants/functions';
import Calendar from './calendar.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import Table from '@/components/Table/index.vue';

export default {
  components: {
    Mypage,
    CommentList,
    ActivityHistory,
    ActivityScheduleEditing,
    Calendar,
    Table
  },
  name: 'MypageList',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      weekday: 0,
      // Activity schedule calendar
      nowDate: new Date().toISOString().slice(0, 10),
      menuDate: false,
      date: new Date().toISOString().substr(0, 10),
      // calender
      
      
      headerActivitySchedule: [
        {
          text: '予定時間',
          value: 'actionPlannedAt',
        },
        {
          text: '顧客名',
          value: 'clientName',
        },
        {
          text: '相手',
          value: 'participants',
        },
        {
          text: '手段',
          value: 'actionMethod',
        },
        {
          text: '優先度',
          value: 'priority',
        },
        {
          text: 'アクション',
          value: 'action',
        },
        {
          text: '',
          value: 'MoveActivityHistory',
          align: 'right',
        },
        {
          text: '編集',
          value: 'edit',
          align: 'center',
        },
        {
          text: '顧客詳細',
          value: 'CustomerDetails',
          align: 'center',
        },
      ],
      currentTable1RowId: null,
      buttonType: null,
    };
  },
  computed: {
    ...mapGetters([
      'clientActionHistoryListMyPage',
      'dateToMyPage',
      'calendarListMyPage',
      'commetListMyPage',
      'dateToCalendarMyPage',
      'dateFromCalendarMyPage',
    ]),

    // Set event calendar
    events() {
      if (this.calendarListMyPage) {
        return this.calendarListMyPage;
      } else return '';
    },

    getDateToMyPage: {
      get() {
        return this.dateToMyPage;
      },
      set(value) {
        this.setDateToMyPage(value);
        this.getClientActionHistoryListMyPage();
      },
    },
  },
  async created(){
    await this.getCommetListMyPage();
  },
  mounted() {
    this.getMonCurrentWeek();
    // this.$refs.calendar.scrollToTime('08:00');
    this.getClientActionHistoryListMyPage();
    this.getCalendarListMyPage();
    // Enum
    this.actionEnumNegotiationExitReasonList();
    this.actionEnumPurchaseRateList();
    this.actionEnumParticipantTypeList();
    this.actionEnumClientActionMethodList();
    this.actionEnumClientActionTypeList();
  },
  methods: {
    ...mapActions([
      'getClientActionHistoryListMyPage',
      'getCalendarListMyPage',
      'getCommetListMyPage',
      'actionGetClientActionHistory',
      'actionEnumPurchaseRateList',
      'actionEnumNegotiationExitReasonList',
      'actionEnumParticipantTypeList',
      'actionEnumClientActionMethodList',
      'actionEnumClientActionTypeList',
      'proClientBasicInfo'
    ]),
    ...mapMutations([
      'setDateToMyPage',
      'setDateFromSelected',
      'setHoursSelected',
      'setMinutesSelected',
      'setActionAtSelected',
      'setParticipantsSelected',
      'setPrioritySelected',
      'setActionMethodIdSelected',
      'setPurchaseRateIdSelected',
      'setCurrentPurchaseRate',
      'setActionSelected',
      'setCurrentFurtherPurchaseRate',
      'setCurrentRenewPurchaseRate',
      'setFurtherPurchaseRateIdSelected',
      'setRenewPurchaseRateIdSelected',
      'setIsComplaintSelected',
      'setNegotiationExitReasonSelected',
      'setClientActionTypeIdSelected',
      'setRemarksSelected',
      'getMonCurrentWeek',
    ]),

    // formatDate(date) {
    //   return moment(date).format('HH:mm');
    // },
    exportTimeJapan,
    // next and back date-picker
    action(action) {
      var myDate = new Date(this.date);
      if (action === 0) {
        myDate.setDate(myDate.getDate() - 1);
      } else {
        myDate.setDate(myDate.getDate() + 1);
      }
      this.date = myDate.toISOString().substr(0, 10);
      this.getDateToMyPage = myDate.toISOString().substr(0, 10);
      this.$refs.table.reset();
    },

    showPopupActivityHistory(item, rowId, buttonType) {
      this.actionGetClientActionHistory(rowId).then(clientActionHistory => {
        if(buttonType === 'Register') {
          // actionAt
          if (item.actionPlannedAt) {
            this.setActionAtSelected(new Date(item.actionPlannedAt).toISOString().slice(0, 10));
          } else {
            this.setActionAtSelected(null);
          }
          this.proClientBasicInfo(item.client.id).then(clientBasicInfo => {
            // FurtherPurchaseRateIdSelected
            this.setFurtherPurchaseRateIdSelected(clientBasicInfo.furtherPurchaseRateId);
            // RenewPurchaseRateIdSelected
            this.setRenewPurchaseRateIdSelected(clientBasicInfo.renewPurchaseRateId);
            // PurchaseRateId
            this.setPurchaseRateIdSelected(clientBasicInfo.purchaseRateId)
          })
        } else {
          // actionAt
          if (clientActionHistory.actionAt) {
            this.setActionAtSelected(clientActionHistory.actionAt);
          } else {
            this.setActionAtSelected(null);
          }
          // FurtherPurchaseRateIdSelected
          this.setFurtherPurchaseRateIdSelected(
            clientActionHistory.furtherPurchaseRateId,
          );
          // RenewPurchaseRateIdSelected
          this.setRenewPurchaseRateIdSelected(
            clientActionHistory.renewPurchaseRateId,
          );

          // PurchaseRateId
          if (clientActionHistory.purchaseRateId) {
            this.setPurchaseRateIdSelected(clientActionHistory.purchaseRateId);

            this.actionEnumPurchaseRateList().then(enumPurchaseRateList => {
              const currentPurchaseRate = enumPurchaseRateList.find(
                element => element.id === clientActionHistory.purchaseRateId,
              );

              const currentFurtherPurchaseRate = enumPurchaseRateList.find(
                element =>
                  element.id === clientActionHistory.furtherPurchaseRateId,
              );

              const currentRenewPurchaseRate = enumPurchaseRateList.find(
                element => element.id === clientActionHistory.renewPurchaseRateId,
              );

              if (currentPurchaseRate) {
                this.setCurrentPurchaseRate(currentPurchaseRate);
              } else {
                this.setCurrentPurchaseRate(null);
              }

              if (currentFurtherPurchaseRate) {
                this.setCurrentFurtherPurchaseRate(currentFurtherPurchaseRate);
              } else {
                this.setCurrentFurtherPurchaseRate(null);
              }

              if (currentRenewPurchaseRate) {
                this.setCurrentRenewPurchaseRate(currentRenewPurchaseRate);
              } else {
                this.setCurrentRenewPurchaseRate(null);
              }
            });
          } else {
            this.setPurchaseRateIdSelected(null);
            this.setCurrentPurchaseRate(null);
            this.setCurrentFurtherPurchaseRate(null);
            this.setCurrentRenewPurchaseRate(null);
          }
        }

        // Common buttonType = 'Edit' or 'Register'
        // participants
        if (clientActionHistory.participants) {
          this.setParticipantsSelected(clientActionHistory.participants);
        } else {
          this.setParticipantsSelected(null)
        }

        // priority
        if (clientActionHistory.priority || clientActionHistory.priority === 0) {
          this.setPrioritySelected(clientActionHistory.priority);
        } else {
          this.setPrioritySelected(null);
        }

        // actionMethodId
        if (clientActionHistory.actionMethodId) {
          this.setActionMethodIdSelected(clientActionHistory.actionMethodId);
        } else {
          this.setActionMethodIdSelected(null);
        }

        // action
        if (clientActionHistory.action) {
          this.setActionSelected(clientActionHistory.action);
        } else {
          this.setActionSelected(null);
        }
          
        // isComplaint
        this.setIsComplaintSelected(clientActionHistory.isComplaint);

        // setNegotiationExitReasonSelected
        if (clientActionHistory.negotiationExitReason) {
          this.setNegotiationExitReasonSelected(clientActionHistory.negotiationExitReason.split(', '));
        } else {
          this.setNegotiationExitReasonSelected([]);
          this.setCurrentPurchaseRate(null);
          this.setCurrentFurtherPurchaseRate(null);
          this.setCurrentRenewPurchaseRate(null);
        }

        // setClientActionTypeIdSelected
        this.setClientActionTypeIdSelected(
          clientActionHistory.clientActionTypeId,
        );
      })
      
      this.currentTable1RowId = rowId;
      // buttonType = 'Edit' or 'Register'
      this.buttonType = buttonType
      this.visible = 1;
    },

    showPopupActivityScheduleEdit(id) {
      this.actionGetClientActionHistory(id).then(clientActionHistory => {
        if (clientActionHistory.actionPlannedAt) {
          this.setDateFromSelected(
            moment(clientActionHistory.actionPlannedAt).format('YYYY-MM-DD'),
          );
          this.setHoursSelected(
            moment(clientActionHistory.actionPlannedAt).format('HH'),
          );
          this.setMinutesSelected(
            moment(clientActionHistory.actionPlannedAt).format('mm'),
          );
        } else {
          this.setDateFromSelected(null);
          this.setHoursSelected(null);
          this.setMinutesSelected(null);
        }
        if (clientActionHistory.participants) {
          this.setParticipantsSelected(clientActionHistory.participants);
        } else {
          this.setParticipantsSelected(null);
        }
        if (clientActionHistory.priority || clientActionHistory.priority === 0) {
          this.setPrioritySelected(clientActionHistory.priority);
        } else {
          this.setPrioritySelected(null);
        }
        if (clientActionHistory.actionMethodId) {
          this.setActionMethodIdSelected(clientActionHistory.actionMethodId);
        } else {
          this.setActionMethodIdSelected(null);
        }
        if (clientActionHistory.action) {
          this.setActionSelected(clientActionHistory.action);
        } else {
          this.setActionSelected(null);
        }
        this.currentTable1RowId = id;
        this.visible = 2;
      });
    },
    reloadTable () {
      this.getClientActionHistoryListMyPage();
    }
  },
};
</script>
<style lang="scss" scoped>
.listCmt {
  // width: 1300px;
  margin: auto;
  padding: 10px;
}
.hr-custom {
  border-top: 1px solid #eaecef;
}
.h2-cus {
  text-align: left;
  font: normal normal bold 20px/30px Noto Sans;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.v-data-table::v-deep {
  tr {
    th{
      color: #000000 !important;
    }
  }
}
</style>
